<template>
  <div>
    <div class="input-group">
      <input type="text" class="form-control" v-model="srchKey" @keypress.enter="callBarcodeListSrch(srchKey)"/>
      <div class="input-group-btn">
        <button type="button" class="btn btn-primary" size="sm"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0;" @click="callBarcodeListSrch(srchKey)">
          검색
        </button>
      </div>
    </div>

    <div v-for="row in srchBarcodeList" :key="row.barcode" class="mt-3">
      <div class="card" style="justify-content: space-between; flex-direction: row">
        <div style="display:flex; flex-direction: row; width: 100%">
          <img :src="row.img_path !== '' ? row.img_path : require('@/assets/mcimages/no_img.png')" style="width: 30%; border-radius: 0.25rem 0 0 0.25rem;">

          <div style="padding: 16px; display: flex; flex-direction: column; justify-content: space-between">
            <div style="font-weight: bold">
              {{ typeof row.product_name_doha !== 'undefined' ? row.product_name_doha.replaceAll('_', ' ') : row.product_name_doha }}
            </div>

            <div style="color: rgb(160, 160, 160);">
              {{ row.barcode }}
            </div>
          </div>
        </div>
        <div style="width: 100px; display: flex; justify-content: flex-end; align-items: flex-end; padding: 16px;">
          <b-button variant="primary" style="width: 100%" @click="selectBarcodeData(row)">선택</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {loggedInfoObject} from "@/state/helpers";
import {evtProdMutations, evtProdStore} from "@/state/modules/eventProductStoreHelper";
import axios from "axios";

export default {
  name: "srchBcodeSingleProdModal",

  data() {
    return {
      srchKey: "",
      srchBarcodeList: []
    }
  },

  computed: {
    ...loggedInfoObject,
    ...evtProdStore
  },

  methods: {
    ...evtProdMutations,

    callBarcodeListSrch(val) {
      console.log('val', this.srchKey)
      if (this.srchKey !== '') {
        const url = `/api/event/group/product/${this.logged_info.biznum}?qu=${val}`

        axios.get(url)
            .then((res) => {
              console.log(res.data)
              this.srchBarcodeList = res.data
              // state.commit('setBarcodeList', res.data)
            })
            .catch(
                err => console.log('Call Srch Res Error :', err)
            )
      }
    },

    selectBarcodeData(row) {
      this.setBarcodeData(row)

      this.$bvModal.hide('srchBarcodeInSingleProd')
    }
  }
}
</script>

<style scoped>

</style>
