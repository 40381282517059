<template>
  <div>
    <div class="modal-body">
      <div class="row mb-3">
        <label class="col-sm-2 col-form-label">
          상품명
        </label>
        <div class="col-sm-10">
          <input class="form-control" placeholder="상품명을 입력해주세요."
                 :value="typeof copyProdData.doha_pname !== 'undefined' ? copyProdData.doha_pname.replaceAll('_', ' ') : copyProdData.doha_pname" readonly>
        </div>
      </div>

      <div class="row mb-3">
        <label class="col-sm-2 col-form-label" style="display: flex; align-items: center">
          가격
        </label>

        <div class="col-sm-5">
          <label class="form-label">원가격</label>
          <input class="form-control" placeholder="원가격을 입력해주세요." v-model="copyProdData.price" v-numeric-only>
        </div>

        <div class="col-sm-5">
          <label class="form-label">할인가격</label>
          <input class="form-control" placeholder="할인가격을 입력해주세요." v-model="copyProdData.saleprice" v-numeric-only>
        </div>
      </div>

      <div class="row mb-2">
        <label class="col-sm-2 col-form-label">
          배지선택
        </label>
        <div class="col-sm-10 col-form-label">
          <div style="display: inline; margin-right: 10px">
            <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="limit" v-model="copyProdData.tmp_limit"/>
            <label for="limit">
              <img :src="require('@/assets/mcimages/marker_limit@3x.png')"
                   class="form-check-label" style="height: 16px;position: relative;top: -1.2px;"
                   for="limit"/>
            </label>
          </div>

          <div style="display: inline; margin-right: 10px">
            <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="manager" v-model="copyProdData.tmp_manager"/>
            <label for="manager">
              <img :src="require('@/assets/mcimages/marker_best@3x.png')"
                   class="form-check-label"
                   style=" height: 16px; position: relative; top: -1.2px;"/>
            </label>
          </div>

          <div style="display: inline; margin-right: 10px">
            <input class="form-check-input" style="margin-right: 6px" type="checkbox" id="notSale" v-model="copyProdData.tmp_multi"/>
            <label for="notSale">
              <img :src="require('@/assets/mcimages/marker_not_sale@3x.png')"
                   class="form-check-label"
                   style="height: 16px;position: relative;top: -1.2px;"/>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">
          한줄글
        </label>
        <div class="col-sm-10">
          <input class="form-control" placeholder="한줄글을 입력해주세요." v-model="copyProdData.comment"/>
        </div>
      </div>
    </div>

    <div class="modal-footer" style="display: flex; justify-content: space-between">
      <div style="display: flex; justify-content: space-between">
        <b-button style="margin-right: 10px;" @click="sendSoldOut(copyProdData)">
          {{ copyProdData.soldout === 'Y' ? '품절해제' : '품절' }}
        </b-button>

        <b-button variant="danger" @click="delSingleProd">
          삭제
        </b-button>
      </div>

      <div style="display: flex; justify-content: space-between">
        <b-button style="margin-right: 10px;" @click="closeModal">
          취소
        </b-button>
        <b-button variant="primary" @click="sendEditItem">
          변경
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";
import axios from "axios";
import {loggedInfoObject} from "@/state/helpers";

export default {
  name: "editProdInfoModal",

  props: {
    modalPosition: {
      type: String
    },
    modalId: {
      type: String
    },
    prodData: {
      type: Object
    },
    selectedEvtData: {
      type: Object
    }
  },

  data() {
    return {
      copyProdData: {},
    }
  },

  computed: {
    ...loggedInfoObject

  },

  mounted() {
    this.copyProdData = {...this.prodData}
    console.log('editProd Mounted', this.copyProdData, this.prodData)
  },

  methods: {
    sendSoldOut(val) {
      let tmp = this.modalPosition !== 'bot'
      this.$emit('childSoldOut', {
        data: val,
        selector: tmp
      })
    },

    delSingleProd() {
      const url = `/api/event/group/product/${this.logged_info.biznum}`

      const param = {
        data: {
          mproduct_event_srl : this.prodData.mproduct_event_srl
        }
      }

      this.$bvModal.msgBoxConfirm(`${this.prodData.doha_pname.replaceAll('_', ' ')}을(를) 삭제하시겠습니까?`, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              axios.delete(url, param)
                  .then((res) => {
                    console.log(res.data)

                    if (res.data.status === 'success') {
                      if (this.modalPosition === 'top') {
                        this.$emit('topReload', (this.selectedEvtData, false))
                      } else {
                        this.$emit('botReload', (this.selectedEvtData) )
                      }
                    }

                    this.closeModal()
                  })
                  .catch(
                      err => console.log('Del Single Prod Error :', err)
                  )
            }
          })
    },

    sendEditItem(bv) {
      console.log('----------------------',this.copyProdData.price, this.copyProdData.saleprice, this.copyProdData.saleprice)
      if (this.copyProdData.price === '') {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('원가격을 확인해주세요.', msgModalOptCenter)
      } else if (this.copyProdData.saleprice === '') {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('할인가격을 확인해주세요.', msgModalOptCenter)
      }  else if (parseInt(this.copyProdData.price) - parseInt(this.copyProdData.saleprice) < 0) {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('원가격과 할인가격을 확인해주세요.', msgModalOptCenter)
      }

      this.$emit('childEditItem', {
          sevl: this.copyProdData,
          wherefrom: this.returnWhereForm(),
          modalId: this.modalPosition === 'top' ? `info${this.prodData.toprank_srl}` : `nsevl${this.prodData.mproduct_srl}`
      })
    },

    closeModal() {
      if (this.modalPosition === 'top') {
        this.$bvModal.hide(`info${this.prodData.toprank_srl}`)
      } else if (this.modalPosition === 'bot') {
        this.$bvModal.hide(`nsevl${this.prodData.mproduct_srl}`)
      }
    },

    returnWhereForm() {
      if (this.modalPosition === 'top') {
        return 'ranked'
      } else {
        return 'unranked'
      }
    },
  }
}
</script>

<style scoped>

</style>
