<template>
  <div>
    <b-modal centered
             :id="'addSpecProdModal'"
             :title="`그룹생성 - ${selectTitle(specType)}`"
             :cancel-title="'취소'"
             :ok-title="'등록'"
             @ok="addEvtGrp"
             @hidden="fieldInit">

      <div class="mb-3" style="font-size: inherit; font-weight: 500">
        특매형태 - {{ returnSpecType() }}
      </div>

      <div class="mb-3" style="display: flex; flex-wrap: wrap; width: 100%">
        <label class="col-form-label" style="width: 72px; padding-right: 8px;">특매명</label>
        <input class="form-control" style="flex: 1" placeholder="특매명 15자 제한" :maxlength="15" v-model="evtTitle">
      </div>

      <div class="mb-3" style="display: flex; flex-wrap: wrap; width: 100%">
        <label class="col-form-label" style="width: 72px; padding-right: 8px;">특매시작일</label>
        <flat-pickr
            v-model="startDate"
            :config="config"
            placeholder="시작일"
            class="form-control"
            style="flex: 1"
        ></flat-pickr>
      </div>

      <div class="" style="display: flex; flex-wrap: wrap; width: 100%">
        <label class="col-form-label" style="width: 72px; padding-right: 8px;">특매종료일</label>
        <flat-pickr
            v-model="endDate"
            :config="config"
            placeholder="종료일"
            class="form-control"
            style="flex: 1"
        ></flat-pickr>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {Korean} from "flatpickr/dist/l10n/ko.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import {loggedInfoObject} from "@/state/helpers";
import {msgModalOptCenter} from "@/components/globalFunctions";

export default {
  name: "addSpecProdModal",

  components: {
    flatPickr
  },

  props: {
    specType: {
      type: String
    }
  },

  data() {
    return {
      config: {
        enableTime: false,
        // minDate: "today",
        dateFormat: "Y-m-d",
        locale: Korean,
      },
      startDate: '',
      endDate: '',
      evtTitle: ''
    }
  },

  computed: {
    ...loggedInfoObject
  },

  methods: {
    returnSpecType() {
      if (this.specType === '0') {
        return '일반특매'
      } else if (this.specType === '1') {
        return '우선특매'
      } else {
        return '최우선특매'
      }
    },

    selectTitle(val) {
      if (val === "0") {
        return '일반특매'
      } else if (val === "1") {
        return '우선특매'
      } else {
        return '최우선특매'
      }
    },

    addEvtGrp(bv) {
      const url = `/api/event/group/${this.logged_info.biznum}?target=${this.specType}&title=${this.evtTitle}&sdate=${this.startDate}&edate=${this.endDate}`

      console.log(this.startDate)

      if (this.evtTitle === '') {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('특매명을 확인해주세요.', msgModalOptCenter)
      } else if (this.startDate === '' || this.startDate === null) {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('특매 시작일을 확인해주세요.', msgModalOptCenter)
      } else if (this.endDate === '' || this.endDate === null) {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('특매 종료일을 확인해주세요.', msgModalOptCenter)
      } else if (Date.parse(this.startDate) > Date.parse(this.endDate)) {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('특매 시작일과 종료일을 확인해주세요.', msgModalOptCenter)
      }

      axios.post(url)
          .then((res) => {
            console.log(res.data)

            if (res.data.status === 'success') {
              this.$emit('childReloadData')
            } else {
              this.$bvModal.msgBoxOk('잠시 후 다시 시도해주세요.', msgModalOptCenter)
            }
          })
          .catch(
              err => console.log('Add Evt Grp Error :', err)
          )
    },

    fieldInit() {
      this.startDate = ''
      this.endDate = ''
      this.evtTitle = ''
    }
  }
}
</script>

<style scoped>

</style>
