<template>
  <div>
    <div class="modal-body">
      <div class="mb-3" style="display: flex;" v-if="false">
        <label class="col-form-label" style="width: 72px; padding-right: 8px; display: flex; align-items: center">특매형태</label>

        <div style="flex: 1; display: flex; flex-direction: column; align-items: flex-start; justify-content: center">
          <div class="mb-2" style="display: flex; justify-content: center; align-items: center">
            <input type="radio" name="editRadio" id="editBtnRadio1" autocomplete="off" v-model="copyEvlData.bg_target"  value="0" style="margin-right: 6px;" checked/>
            <label for="editBtnRadio1" style="margin: 0">
              일반특매
            </label>
          </div>

          <div class="mb-2" style="display: flex; justify-content: center; align-items: center">
            <input type="radio" name="editRadio" id="editBtnRadio2" autocomplete="off" v-model="copyEvlData.bg_target" value="1" style="margin-right: 6px;"/>
            <label for="editBtnRadio2" style="margin: 0">
              우선특매
            </label>
          </div>

          <div class="mb-2" style="display: flex; justify-content: center; align-items: center">
            <input type="radio" name="editRadio" id="editBtnRadio3" autocomplete="off" v-model="copyEvlData.bg_target" value="2" style="margin-right: 6px;"/>
            <label for="editBtnRadio3" style="margin: 0">
              최우선특매
            </label>
          </div>
        </div>
      </div>

      <div class="mb-3" style="display: flex; flex-wrap: wrap; width: 100%">
        <label class="col-form-label" style="width: 72px; padding-right: 8px;">특매명</label>
        <input class="form-control" style="flex: 1" placeholder="특매명 15자 제한" v-model="copyEvlData.title" :maxlength="15">
      </div>

      <div class="mb-3" style="display: flex; flex-wrap: wrap; width: 100%">
        <label class="col-form-label" style="width: 72px; padding-right: 8px;">특매시작일</label>
        <flat-pickr
            v-model="copyEvlData.start_date"
            :config="config"
            placeholder="시작일"
            class="form-control"
            style="flex: 1"
        ></flat-pickr>
      </div>

      <div class="" style="display: flex; flex-wrap: wrap; width: 100%">
        <label class="col-form-label" style="width: 72px; padding-right: 8px;">특매종료일</label>
        <flat-pickr
            v-model="copyEvlData.end_date"
            :config="config"
            placeholder="종료일"
            class="form-control"
            style="flex: 1"
        ></flat-pickr>
      </div>
    </div>

    <div class="modal-footer" style="display: flex; justify-content: space-between">
      <b-button variant="danger" @click="delEvtGrp">
        삭제
      </b-button>

      <div style="display: flex; justify-content: space-between">
        <b-button @click="$bvModal.hide(`${cardId}${evlData.toprank_event_srl}`)" style="margin-right: 10px;">
          취소
        </b-button>
        <b-button variant="primary" @click="editEvtGrp">
          변경
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>
import {Korean} from "flatpickr/dist/l10n/ko.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

export default {
  name: "editSpecInfoModal",

  components: {
    flatPickr
  },

  props: {
    evlData: {
      type: Object
    },
    selectedCard: {
      type: Boolean
    }
  },

  data() {
    return {
      config: {
        enableTime: false,
        // minDate: "today",
        dateFormat: "Y-m-d",
        locale: Korean,
      },
      startDate: '',
      endDate: '',
      cardId: '',
      copyEvlData: {}
    }
  },

  computed: {
    ...loggedInfoObject
  },

  mounted() {
    console.log(this.evlData)

    this.copyEvlData = {...this.evlData}

    // 특매 그룹을 선택했을때 보이는 modal id 값과 선택하지 않았을때 나타나는 modal id 값이 다름
    if (this.selectedCard) {
      this.cardId = 'editSelEventCard'
    } else {
      this.cardId = 'editEventCard'
    }
  },

  methods: {
    isMatched(obj1, obj2) {
      for (let key in obj1) {
        if (!(key in obj2) || obj1[key] !== obj2[key]) {
          return false;
        }
      }
      return true;
    },

    editEvtGrp(bv) {
      const url = `/api/event/group/${this.logged_info.biznum}`

      const param = {
        pos_bg_code: this.evlData.pos_bg_code,
        target: this.copyEvlData.bg_target,
        title: this.copyEvlData.title,
        sdate: this.copyEvlData.start_date,
        edate: this.copyEvlData.end_date
      }

      if (param.title === '') {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('특매명을 확인해주세요.', msgModalOptCenter)
      } else if (param.sdate === '' || param.sdate === null) {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('특매 시작일을 확인해주세요.', msgModalOptCenter)
      } else if (param.edate === '' || param.edate === null) {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('특매 종료일을 확인해주세요.', msgModalOptCenter)
      } else if (Date.parse(param.sdate) > Date.parse(param.edate)) {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('특매 시작일과 종료일을 확인해주세요.', msgModalOptCenter)
      } else if (this.isMatched(this.copyEvlData, this.evlData)) {
        bv.preventDefault();
        return this.$bvModal.msgBoxOk('변경된 사항이 없습니다.', msgModalOptCenter)
      }

      this.$bvModal.msgBoxConfirm(`${this.evlData.title} 그룹의 정보를 변경하시겠습니까?`, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              axios.put(url, param)
                  .then((res) => {
                    console.log(res.data)

                    if (res.data.status === 'success') {
                      this.$emit('childReloadData')
                      this.$bvModal.hide(`${this.cardId}${this.evlData.toprank_event_srl}`)
                    } else {
                      if (res.data.msg === 'duplicate') {
                        this.$bvModal.msgBoxOk(`동일 기간 내에 중복되는 상품이 있어 수정이 불가합니다.`, msgModalOptCenter)
                      } else {
                        this.$bvModal.msgBoxOk('그룹 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.', msgModalOptCenter)
                      }

                    }
                  })
                  .catch(
                      err => console.log('Edit Event Group Error :', err)
                  )
            }
          })

    },

    delEvtGrp() {
      const url = `/api/event/group/${this.logged_info.biznum}?pos_bg_code=${this.evlData.pos_bg_code}`

      this.$bvModal.msgBoxConfirm(`${this.evlData.title} 그룹을 삭제하시겠습니까?`, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              console.log('OK')

              axios.delete(url)
                  .then((res) => {
                    if (res.data.status === 'success') {
                      this.$bvModal.hide(`${this.cardId}${this.evlData.toprank_event_srl}`)
                    } else {
                      this.$bvModal.msgBoxOk('그룹 삭제에 실패하였습니다.\n잠시 후 다시 시도해주세요.', msgModalOptCenter)
                    }

                    this.$emit('childReloadData')
                  })
                  .catch(
                      err => console.log('Delete Event Group Error :', err)
                  )
            }
          })


    }
  }
}
</script>

<style scoped>

</style>

