<template>
  <b-modal centered
           :id="`registerExcelFile`"
           :title="`상품장 엑셀파일 추가`"
           :ok-title="'등록'"
           @ok="uploadExcel"
           :cancel-title="'취소'"
           @hidden="excelFile = null">
    <div class="mb-2">
      <div style="display: flex; justify-content: space-between; align-items: center">
        <label class="form-label">상품장 엑셀 파일 선택</label>
        <div>
          <button type="button"
                  class="btn btn-soft-light"
                  style="padding: 0 4px;"
                  @click="$bvModal.show('info')">
            <i class="uil uil-question-circle"></i>
          </button>
          <b-modal
              id="info"
              ok-only
              ok-title="닫기"
              title="도움말">
            <p style="margin: 0 0 16px 0;">
              아래의 이미지와 설명에 따라서 엑셀파일을 업로드 시켜주세요.
            </p>
            <center>
              <img style="border:2px solid blue; margin-bottom: 16px; width: 100%"
                   :src="require('@/assets/mcimages/exampleExcelEvent.png')">
            </center>
            <p style="margin: 0; color: #dd4b39;">
              ※ 이미지와 같이 엑셀의 1번째줄은 제목줄로
              <br>"바코드", "상품명", "정상판가", "행사가"라는 컬럼을 만들어 주시고
              <br>아래에는 해당 컬럼에 맞는 정보를 넣어주시면 됩니다.
            </p>
            <br><br>
            <center>
              <img style="border:2px solid blue; margin-bottom: 16px; width: 100%"
                   :src="require('@/assets/mcimages/exExcelEvent1.png')">
            </center>
            <p style="margin: 0; color: #dd4b39;">
              ※ 다운받은 상품장파일을 사용할 시 위 이미지에서와 같이
              <br> 좌측 첫번째 탭의 상품만 등록됩니다.
            </p>
          </b-modal>
        </div>
      </div>

      <div class="p-0">
        <input ref="prodExcel" class="form-control" type="file" style="display: inline; vertical-align: middle"
               accept=".xls, .xlsx" @change="selectExcel"/>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import {msgModalOptCenter} from "@/components/globalFunctions";
import {loggedInfoObject} from "@/state/helpers";

export default {
  name: "registerExcelFileModal",

  props: {
    eventData: {
      type: Object
    }
  },

  data() {
    return {
      excelFile: null
    }
  },

  computed: {
    ...loggedInfoObject
  },

  methods: {
    //upload 엑셀
    uploadExcel(bv) {
      console.log('uploadExcel')

      let url = `/api/event/group/product/excel/${this.logged_info.biznum}`

      let fd = new FormData()

      fd.append('excel', this.excelFile)
      fd.append('pos_bg_code', this.eventData.pos_bg_code)

      if (this.excelFile !== null) {
        axios.post(url, fd)
            .then((res) => {
              console.log(res.data)
              if (res.data.status === 'fail') {
                // 중복된 상품이 있습니다. `${res.data.msg}`

                if (res.data.msg === 'duplicate') {
                  this.$bvModal.msgBoxOk(`동일 기간내에 있는 행사중 중복되는 상품이 있어 등록이 불가합니다.`, msgModalOptCenter)
                } else if (res.data.msg !== 'duplicate') {
                 this.$bvModal.msgBoxOk(`등록에 실패하였습니다.\n\n${res.data.msg}`, msgModalOptCenter)
                } else {
                  this.$bvModal.msgBoxOk(`등록에 실패하였습니다.\n잠시 후 다시 시도해주세요.`, msgModalOptCenter)
                }
              } else {
                this.$bvModal.msgBoxOk('성공적으로 등록되었습니다.', msgModalOptCenter)
                this.$emit('childReloadData', this.eventData)
              }
            })
            .catch(
                err => console.log(err)
            )
      } else {
        bv.preventDefault();
        this.$bvModal.msgBoxOk('파일이 존재하지 않습니다.\n파일을 선택해주세요.', msgModalOptCenter)
      }
    },

    //엑셀 파일 선택
    selectExcel() {
      let input = event.target;
      console.log(input.files[0])
      this.excelFile = input.files[0]
    },
  }
}
</script>

<style scoped>

</style>
